<template>
    <div class="fullscreen">
        <div class="wheel-container">
            <svg :width="size" :height="size" :viewBox="`0 0 ${size} ${size}`" class="wheel" ref="wheel">
                <g v-for="(prize, index) in prizes" :key="index">
                    <path :d="drawSegment(index)" :fill="getSegmentColor(index)" stroke="#fff" stroke-width="1"></path>

                    <text
                        :x="getTextX(index)"
                        :y="getTextY(index)"
                        :transform="getTextRotation(index)"
                        text-anchor="middle"
                        fill="#000"
                        :style="{
                            fontSize: `${size / 30}px`,
                            'text-transform': 'uppercase',
                            'font-weight': 'bold',
                            'letter-spacing': '1px',
                        }"
                    >
                        {{ prize.name }}
                    </text>
                </g>
            </svg>

            <div class="spin-button-group" @click="spinWheel" :disabled="isSpinning">
                <div class="spin-button-arrow"></div>
                <div class="spin-button-circle">
                    <div class="spin-button">SPIN</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { gsap } from 'gsap'
import confetti from 'canvas-confetti'

export default {
    data() {
        return {
            size: this.computeSize(), // Dynamische Größe des Rads
            prizes: [
                { name: 'Preis 1', probability: 0.1 },
                { name: 'Preis 2', probability: 0.1 },
                { name: 'Preis 3', probability: 0.1 },
                { name: 'Preis 4', probability: 0.1 },
                { name: 'spin again', probability: 0.8 },
                { name: 'Preis 6', probability: 0.1 },
                { name: 'Preis 7', probability: 0.1 },
                { name: 'Preis 8', probability: 0.1 },
                { name: 'Preis 9', probability: 0.1 },
                { name: 'Preis 10', probability: 0.1 },
            ],
            selectedPrize: null,
            isSpinning: false,
            textRadius: null,
            winSound: null,
            segmentSound: null,
            previousSegment: null, // Verfolgt das zuvor erreichte Segment
        }
    },
    mounted() {
        // Radius für die Textposition berechnen
        this.textRadius = this.size / 2 - 30 // Abstand vom Mittelpunkt

        // Event Listener für Fenstergröße hinzufügen
        window.addEventListener('resize', this.onResize)

        // Event Listener für Tastendruck hinzufügen
        window.addEventListener('keydown', this.onKeyDown)

        this.winSound = new Audio('https://app.wellcomefit.ch/img/einmalig/winSound.mp3')
        this.segmentSound = new Audio('https://app.wellcomefit.ch/img/einmalig/segmentSound.mp3')
    },
    beforeDestroy() {
        // Event Listener entfernen, wenn die Komponente zerstört wird
        window.removeEventListener('resize', this.onResize)

        // Event Listener für Tastendruck entfernen
        window.removeEventListener('keydown', this.onKeyDown)
    },
    methods: {
        computeSize() {
            // Berechne die Größe als Minimum von 80% der Viewport-Breite und -Höhe
            const width = window.innerWidth * 0.8
            const height = window.innerHeight * 0.8
            return Math.min(width, height)
        },
        onResize() {
            // Aktualisiere die Größe und abhängige Werte bei Größenänderung des Fensters
            this.size = this.computeSize()
            this.textRadius = this.size / 2 - 30
            // Optional: Aktualisiere das Rad neu zeichnen oder andere Abhängigkeiten
        },

        onKeyDown(event) {
            // Überprüfe, ob die "s"-Taste gedrückt wurde (Groß- oder Kleinschreibung)
            if (event.key === 's' || event.key === 'S') {
                this.spinWheel()
            }
        },
        drawSegment(index) {
            const numSegments = this.prizes.length
            const angle = (2 * Math.PI) / numSegments
            const startAngle = index * angle
            const endAngle = startAngle + angle
            const radius = this.size / 2

            const x1 = radius + radius * Math.cos(startAngle)
            const y1 = radius + radius * Math.sin(startAngle)
            const x2 = radius + radius * Math.cos(endAngle)
            const y2 = radius + radius * Math.sin(endAngle)

            return `
              M ${radius},${radius}
              L ${x1},${y1}
              A ${radius},${radius} 0 0,1 ${x2},${y2}
              Z
               `
        },
        getSegmentColor(index) {
            /* const colors = [
                '#FFCDD2',
                '#F8BBD0',
                '#E1BEE7',
                '#D1C4E9',
                '#C5CAE9',
                '#BBDEFB',
                '#B3E5FC',
                '#B2EBF2',
                '#B2DFDB',
                '#C8E6C9',
            ]
            return colors[index % colors.length]*/
            return index % 2 ? '#ffffff' : '#dedede'
        },
        getTextX(index) {
            const radius = this.size / 2
            const angle = ((2 * Math.PI) / this.prizes.length) * (index + 0.6)
            const textRadius = radius * 0.6 // Anpassung des Abstands vom Mittelpunkt
            return radius + textRadius * Math.cos(angle)
        },
        getTextY(index) {
            const radius = this.size / 2
            const angle = ((2 * Math.PI) / this.prizes.length) * (index + 0.6)
            const textRadius = radius * 0.6 // Anpassung des Abstands vom Mittelpunkt
            return radius + textRadius * Math.sin(angle)
        },
        getTextRotation(index) {
            const numSegments = this.prizes.length
            const angle = (360 / numSegments) * index + 180 / numSegments
            const x = this.getTextX(index)
            const y = this.getTextY(index)
            return `rotate(${angle} ${x} ${y})`
        },
        selectPrize() {
            const rand = Math.random()
            let cumulativeProbability = 0
            for (let prize of this.prizes) {
                cumulativeProbability += prize.probability
                if (rand <= cumulativeProbability) {
                    this.selectedPrize = prize
                    break
                }
            }
        },
        resetWheel() {
            gsap.set(this.$refs.wheel, {
                rotation: 0,
            })
            this.currentRotation = 0
        },
        playSegmentSound(newSegment) {
            // Wenn das neue Segment vom vorherigen abweicht, spiele den Sound ab
            if (newSegment !== this.previousSegment) {
                this.segmentSound.currentTime = 0 // Startet den Sound von vorne
                this.segmentSound.play()
                this.previousSegment = newSegment // Aktualisiert das vorherige Segment
            }
        },
        spinWheel() {
            if (this.isSpinning) return
            this.resetWheel()
            this.isSpinning = true
            this.selectPrize()

            const minSpins = 20 // Mindestanzahl an Umdrehungen
            const numSegments = this.prizes.length
            const segmentAngle = 360 / numSegments
            const prizeIndex = this.prizes.indexOf(this.selectedPrize)
            const segmentCenterAngle = segmentAngle * prizeIndex + segmentAngle / 2
            const rotationNeeded = minSpins * 360 + (270 - segmentCenterAngle)

            gsap.to(this.$refs.wheel, {
                rotation: rotationNeeded,
                duration: 5,
                ease: 'power4.out',
                onUpdate: () => {
                    const currentRotation = gsap.getProperty(this.$refs.wheel, 'rotation') % 360
                    const currentSegment = Math.floor(currentRotation / segmentAngle)
                    this.playSegmentSound(currentSegment)
                },
                onComplete: () => {
                    this.winSound.play()
                    this.$toast.success(`Herzlichen Glückwunsch! Du hast ${this.selectedPrize.name} gewonnen!`)
                    // Starte die Konfetti-Explosion
                    this.launchConfetti()
                    this.isSpinning = false
                    this.currentRotation = rotationNeeded % 360
                },
            })
        },
        launchConfetti() {
            const duration = 3 * 1000 // Konfetti läuft 3 Sekunden lang
            const animationEnd = Date.now() + duration

            function randomInRange(min, max) {
                return Math.random() * (max - min) + min
            }

            ;(function frame() {
                const timeLeft = animationEnd - Date.now()

                if (timeLeft <= 0) return // Stoppe die Animation, wenn die Zeit abgelaufen ist

                const particleCount = Math.floor(10 * (timeLeft / duration)) // Dynamisch weniger Partikel mit der Zeit

                // Explosion mit einem Spread (Verteilung der Partikel) und größerem Bereich
                confetti({
                    particleCount: particleCount,
                    startVelocity: 25, // Moderate Startgeschwindigkeit
                    spread: 90, // Breiterer Spread für schönere visuelle Wirkung
                    origin: {
                        x: randomInRange(0.2, 0.8), // Zufällige Positionen im horizontalen Bereich
                        y: randomInRange(0.2, 0.4), // Position über dem Bildschirm für realistischere Gravitation
                    },
                    gravity: randomInRange(0.5, 0.7), // Realistische Schwerkraft
                    scalar: randomInRange(0.6, 1), // Kleinere bis mittlere Partikel
                })

                // Weniger häufiges requestAnimationFrame für bessere Performance
                setTimeout(() => {
                    requestAnimationFrame(frame)
                }, 100) // Alle 100ms, um die CPU-Last zu minimieren
            })()
        },
    },
}
</script>

<style scoped>
/* Vollbild-Container */
.fullscreen {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Rad-Container */
.wheel-container {
    position: relative;
    width: 80vmin;
    height: 80vmin;
}

/* Das Rad selbst */
.wheel {
    transform-origin: center center;
    margin: 0 auto;
    display: block;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 0, 0, 0.14), 0 0 15px rgba(0, 0, 0, 0.12);
}

/* Wrapper für Kreis und Dreieck */
.spin-button-group {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Schatten auf Gruppe anwenden */
    filter: drop-shadow(0 3px 3px rgba(0, 0, 0, 0.2));
}

/* Weißes Dreieck oben */
.spin-button-arrow {
    width: 0;
    height: 0;
    border-left: 5vmin solid transparent;
    border-right: 5vmin solid transparent;
    border-bottom: 5vmin solid white;
    margin-bottom: -2.3vmin; /* Überlappung mit dem Kreis */
}

/* Weißer Kreis um den Button */
.spin-button-circle {
    position: relative;
    width: 13vmin;
    height: 13vmin;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Der eigentliche Spin-Button */
.spin-button {
    width: 10vmin;
    height: 10vmin;
    background-color: #00963f;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2vmin;
    cursor: pointer;
    user-select: none;
    letter-spacing: 1.2px;
    font-weight: bold;
}

/* Deaktivierter Button */
.spin-button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}
</style>
